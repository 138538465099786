<template lang="html">
  <div id="access-page">

    <section class="main-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-lg-9 col-xl-7 col-content">
            <div class="box-logo">
              <img src="public/images/logo-white.svg" alt="Asterion Capital Solutions">
            </div>

            <div class="box-form">
              <h4 class="pb-2 mb-4 text-center">Crear nueva cuenta</h4>

              <b-form @submit="onSubmit">
                <b-form-group class="cus-f-group-1 col-12" label="Nombre completo">
                  <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="cus-f-group-1 col-12" label="Teléfono">
                  <b-form-input type="text" minlength="10" maxlength="10" v-model="form.phone" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="cus-f-group-1 col-12" label="Correo electrónico">
                  <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="cus-f-group-1 col-12" label="Contraseña">
                  <b-form-input type="password" v-model="form.password" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="cus-f-group-1 col-12" label="Confirmar contraseña">
                  <b-form-input type="password" v-model="form.copassword" required placeholder=""></b-form-input>
                </b-form-group>

                <div class="col-12">
                  <div class="row">
                    <div class="col-12 mt-3 text-center">
                      <b-button type="submit" class="btn-s1 bg-white">Registrarse</b-button>
                    </div>

                    <div class="col-12 mt-2 mt-sm-3 text-center order-6">
                      <p>¿Ya tienes una cuenta? <router-link class="f-w-600" to="/">Inicia sesión</router-link></p>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      }
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
