<template lang="html">
  <div id="access-page">

    <section class="main-section">
      <div class="container main-container">
        <div class="row">
          <div class="col-lg-9 col-xl-7 col-content">
            <div class="box-logo">
              <img src="public/images/logo-white.svg" alt="Asterion Capital Solutions">
            </div>

            <div class="box-form">
              <h4 class="pb-2 mb-4 text-center">Ingresa el código de seguridad enviado al número con terminación **** {{ this.$root.user.phone_subs}}</h4>

              <b-form @submit="onSubmit">
                <div class="box-2fa">
                  <div class="bx" v-bind:class="{ focused : isFocused }">
                    <b-form-input required type="number" v-model="form.code" @keypress="isNumber($event)" @paste="noPaste" @keydown="limitChars" ref="inputCode"></b-form-input>

                    <div class="box-number" v-bind:class="{ active : splittedNumber[0] }">{{ splittedNumber[0] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[1] }">{{ splittedNumber[1] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[2] }">{{ splittedNumber[2] }}</div>
                    <div class="box-number" v-bind:class="{ active : splittedNumber[3] }">{{ splittedNumber[3] }}</div>

                    <button class="btn-focus" @click="focusOnInput" @mouseleave="unfocus"></button>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">

                    <div class="mt-4 col-12 text-center">
                      <p>
                        ¿No recibiste el código? <a class="c-pointer f-w-600" @click="sendSms">Reenviar código</a>
                      </p>
                    </div>

                    <div class="col-12 mt-3 text-center">
                      <b-button type="submit" class="btn-s1 bg-white">Entrar</b-button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        code: '',
      },

      isFocused: false,
      splittedNumber: [],
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    noPaste(event){
      event.preventDefault();
    },

    isNumber: function(event) {
      event = (event) ? event : window.event;
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    limitChars(event) {
      var key = event.keyCode || event.charCode;
      // console.log(key);
      if(key !== 8 && key !== 46) { // Allow backspace & delete buttons
        if(key >= 37 && key <= 40) { // Block navigaton buttons
          event.preventDefault();
        }else {
          if ( this.form.code.length >= 4 ) { // Allow only 4 digits
            event.preventDefault();
          }
        }
      }
    },

    focusOnInput() {
      this.isFocused = true;
      this.$refs.inputCode.focus();
    },

    unfocus() {
      this.isFocused = false;
    },

    onSubmit(event) {
      event.preventDefault();
      if(this.form.code.length == 4){
          this.modal.block = true;
            this.modal.icon = '';
            this.modal.msg = 'Cargando, por favor espere...';
            this.$refs.modal.open();
            axios.post(tools.url("/api/validatesendsmscode"),{code:this.form.code}).then((response)=>{
                if(response.data.status == false){
                    this.modal.block = false;
                    this.modal.icon = 'error';
                    this.modal.msg = response.data.msg;
                    this.form.code = null;
                    this.splittedNumber = [];
                } 
                else{
                    this.modal.block = false;
                    this.$refs.modal.close();
                    this.$router.push('/dashboard');
                }
            }).catch((error)=>{
              this.modal.block = false;
                    this.modal.icon = 'error';
                    this.modal.msg = error.data.msg;
                    this.form.code = null;
                    this.splittedNumber = [];
            });

      }
    },

    sendSms(){
      axios.post(tools.url("/api/sendsmscode"),{email:this.$root.user.email}).then((response)=>{

      }).catch((error)=>{
             this.handleErrors(error);
      });
    }
  },

  watch: {
    'form.code'(val, oldVal){
      this.splittedNumber = val.split("");
    }
  }
}
</script>
