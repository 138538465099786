<template lang="html">
  <header id="d-header">
    <div class="btn-sidebar" @click="$parent.isMenuVisible = true">
      <i class="far fa-bars"></i>
    </div>

    <div class="row no-gutters">
      <div class="col-4 col-section-title">
        <h4 class="_title">{{ title }}</h4>
      </div>

      <div class="col-8 col-header-menu">
        <div class="box">
          <div class="col col-text">
            <h6 class="txt-welcome">Bienvenido de nuevo</h6>

            <b-nav-item-dropdown class="user-menu" right>
              <template #button-content>
                <span>{{$root.user.name}} {{$root.user.lastname}}</span>
              </template>
              <b-dropdown-item :to="{ name: 'accMyAccountPage' }">Cambiar datos</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'accChangeImagePage' }">Cambiar imagen</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'accChangePasswordPage' }">Cambiar contraseña</b-dropdown-item>
              <b-dropdown-item  @click="handleLogout">Salir</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>

          <div class="col col-photo">
            <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+$root.user.imageUrl+')' }"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  props: [
    'title'
  ],
  data() {
    return {
      
    }
  },

  methods: {
    handleLogout(){
            axios.post(tools.url("/api/logout")).then((response)=>{
                this.$root.user = null;
                this.$root.logged = false;
                this.$router.push('/');
            }).catch(()=>{});
    }
   
  },
  mounted() {

  }
}
</script>
