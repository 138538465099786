<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Empresas</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 outline-black mr-2">Exportar</button>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <!-- <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> -->

    <section class="box-white-s1 results-section">
      <div class="row">
        <!-- <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option value="a">Mayor a menor</b-form-select-option>
                <b-form-select-option value="b">Menor a mayor</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div> -->

        <div class="col-lg-12 content-sample-1" v-for="(c, cInx) in companies" :key="'cInx-'+cInx" @click="openDetail(cInx)" style="cursor: pointer;">
          <div class="box">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="name">{{ c.name }}</h5>
                    <h6 class="subtitle">{{ c.url }}</h6>
                  </div>

                  <div class="col box-tab">
                    <span :class="'tab '+c.compType">{{ c.compName }}</span>
                  </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Giro: {{ c.turn }}</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Ventas: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.sales) }} MXN</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Acciones: {{c.actions}}</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>País: {{ c.country }}</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Crecimiento: {{c.growth}}%</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Valor invertido: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.investedvalue) }} MXN</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Valor: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.value) }} MXN</h5>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-txt">
                  <h5>Utilidad: {{c.utility}}%</h5>
                </div>

                <!-- <div class="col-12 col-xl-4 col-txt text-center text-xl-right">
                  <router-link class="_link" to="">Ver acciones</router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <sweet-modal ref="modalDetail">
        <div class="col-lg-12 content-sample-1">
          <div class="box">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+rowdetail.imageUrl+')' }"></div>
            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="name">{{ rowdetail.name }}</h5>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>TIR: {{ rowdetail.tir }}</h5>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>ROE: {{ rowdetail.roe }}</h5>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>ROI: {{ rowdetail.roi }}</h5>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>ROA: {{ rowdetail.roa }}</h5>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>Porcentaje de la participación: {{ rowdetail.participation_percentage }}</h5>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <h5>Valor accionario de la compañía: {{ rowdetail.company_share_value }}</h5>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-txt" style="text-align: left;">
                  <a v-for="(doc,indxd) in rowdetail.documents" :key="'doc'+indxd" :href="doc.documentUrl" target="_blank" class="btn btn-info" style="background-color: #000; border-color: #000; margin-right: 10px;"><i class="fas fa-download"></i></a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,

      companies: [
        /*{ imgUrl: 'public/images/pages/account/company-1.jpg', title: 'Sustam S.A. de C.V.', url: 'sustam.com', compType: 'tab-1', compName: 'Empresa propia' },
        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },

        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },
        { imgUrl: 'public/images/pages/account/company-2.jpg', title: 'Brandname', url: 'brandname.com', compType: 'tab-2', compName: 'Inversionista mayorista' },
        { imgUrl: 'public/images/pages/account/company-3.jpg', title: 'Padel Factory S.A. de C.V.', url: 'padelfactory.com', compType: 'tab-3', compName: 'Inversionista minorista' },
      */
      ],
      rowdetail:{}
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
        axios.get(tools.url("/api/companies"), this.form).then((response)=>{
            this.companies = response.data;
        }).catch((error)=>{
              this.handleErrors(error);
        });
    },
    openDetail(indx){
        this.rowdetail = this.companies[indx];
      this.$refs.modalDetail.open();
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
    this.getRow();
  }
}
</script>
