<template lang="html">
    <div id="content-t1-page">
      <section class="page-top-section">
        <div class="row">
          <div class="col-lg-5 col-title">
            <h2>Seguros</h2>
          </div>
  
          <!-- <div class="col-lg-7 col-actions">
            <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
          </div> -->
        </div>
      </section>
  
      <!-- <section class="top-search-section">
        <b-form class="box-white-s1" @submit="onSubmit">
          <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>
  
          <b-form-input
            class="col"
            v-model="form.keywords"
            type="text"
            placeholder="Buscar..."
          ></b-form-input>
  
          <b-form-select class="col" v-model="form.option">
            <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
            <b-form-select-option value="a">Opción A</b-form-select-option>
            <b-form-select-option value="b">Opción B</b-form-select-option>
          </b-form-select>
        </b-form>
      </section> -->
  
      <section class="box-white-s1 results-t2-section">
        <div class="row">
          <!-- <div class="col-12 col-filters">
            <b-form @submit="onSubmit">
              <div class="box-filter-s1">
                <label>Filtrar por</label>
  
                <b-form-select v-model="filters">
                  <b-form-select-option :value="null">Actualización</b-form-select-option>
                  <b-form-select-option value="a">Mayor a menor</b-form-select-option>
                  <b-form-select-option value="b">Menor a mayor</b-form-select-option>
                </b-form-select>
              </div>
            </b-form>
          </div> -->
  
          <div class="col-sm-6 col-xl-4 xxl-4 content-sample-2" v-for="(o, oInx) in rows" :key="'oInx-'+oInx">
            <div class="box">
              <!-- <div class="col-12 placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+o.imgUrl+')' }"></div> -->
  
              <div class="col-12 col-info">
                <h5 class="name">{{ o.name }}</h5>
              </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Cobertura</h6>
                    <h6>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(o.coverage) }} </h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Empresa aseguradora</h6>
                    <h6>{{ o.company }}</h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Tipo de póliza</h6>
                    <h6>{{ o.policy_type }}</h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Beneficiarios</h6>
                    <h6>{{ o.policy_beneficiaries }}</h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Vigencia de la póliza</h6>
                    <h6>{{ o.validity }}</h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Monto de suma asegurada</h6>
                    <h6>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(o.sum_insured_amount) }}</h6>
                  </div>
                </div>
                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Comentarios</h6>
                    <h6>{{ o.comments }}</h6>
                  </div>
                </div>

                <div class="col-12" style="padding: 5px;">
                  <div class="col box-title">
                    <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">PDF</h6>
                    <a :href="o.documentUrl" target="_blank" class="btn btn-info" style="background-color: #000; border-color: #000; margin-right: 10px;"><i class="fas fa-download"></i></a>
                  </div>
                </div>


                

            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // == Formulario de búsqueda ==
        form: {
          keywords: null,
          option: null,
        },
        // == ==
  
        filters: null,
        category: 0,
  
        rows: [],
      }
    },
  
    methods: {
      onSubmit(event) {
        event.preventDefault();
        console.log('Buscando...');
      },
        getRow(){
            axios.get(tools.url("/api/insurances"), this.form).then((response)=>{
                this.rows = response.data;
            }).catch((error)=>{
                this.handleErrors(error);
            });
        }
    },
  
    mounted() {
      this.$parent.sectionTitle = 'Patrimonio';
      this.getRow();
    }
  }
  </script>
  