<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Sistema financiero</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <!-- <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>-->

        <b-form-select class="col" v-model="form.option" @change="changeMonth">
          <b-form-select-option :value="null">Todos los meses</b-form-select-option>
          <b-form-select-option value="Enero">Enero</b-form-select-option>
          <b-form-select-option value="Febrero">Febrero</b-form-select-option>
          <b-form-select-option value="Marzo">Marzo</b-form-select-option>
          <b-form-select-option value="Abril">Abril</b-form-select-option>
          <b-form-select-option value="Mayo">Mayo</b-form-select-option>
          <b-form-select-option value="Junio">Junio</b-form-select-option>
          <b-form-select-option value="Julio">Julio</b-form-select-option>
          <b-form-select-option value="Agosto">Agosto</b-form-select-option>
          <b-form-select-option value="Septiembre">Septiembre</b-form-select-option>
          <b-form-select-option value="Octubre">Octubre</b-form-select-option>
          <b-form-select-option value="Noviembre">Noviembre</b-form-select-option>
          <b-form-select-option value="Diciembre">Diciembre</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> 

    <section class="box-white-s1 results-section">
      <div class="row">
        <div class="col-lg-12 content-sample-1" v-for="(c, cInx) in rows" :key="'cInx-'+cInx">
          <div class="box">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }"></div>

            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h5 class="d-block mb-2 name">{{ c.name }} | {{ c.month }}</h5>
                    <h6 class="d-block subtitle">{{ c.type }}</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Disponible: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.aviable) }}</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Tasa: {{c.rate}}%</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Consumido: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.consumed) }}</h5>
                </div>

                <div class="col-sm-6 col-txt">
                  <h5>Vencimiento: {{c.expiration}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,

      rows: [],
      rowsall:[],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
        axios.get(tools.url("/api/financialliabilities"), this.form).then((response)=>{
            this.rows = response.data;
            this.rowsall = response.data;
        }).catch((error)=>{
            this.handleErrors(error);
        });
    },
    changeMonth(){
        var newdta = [];
        if(this.form.option == null){
          newdta = this.rowsall;
        }
        else{
          for (let x = 0; x < this.rowsall.length; x++) {
            if(this.rowsall[x]['month'] == this.form.option){
                newdta.push(this.rowsall[x]);
            }
          }
        }
        this.rows = newdta;
        
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Pasivos';
    this.getRow();
  }
}
</script>
