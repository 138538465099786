<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Cambiar contraseña</h2>
        </div>
      </div>
    </section>

    <section class="account-section">
      <div class="box box-white-s1">
        <b-form @submit="onSubmit">
          <div class="row">
            <!-- <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Contraseña actual">
                <b-form-input type="password" v-model="form.oldpass" required placeholder=""></b-form-input>
              </b-form-group>
            </div> -->

            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Nueva contraseña">
                <b-form-input type="password" v-model="form.npassword" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Confirmar contraseña">
                <b-form-input type="password" v-model="form.conpassword" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 bg-black">Cambiar contraseña</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        
      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario  ==
      form: {
        npassword: null,
        conpassword: null,
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      if(this.form.npassword !== this.form.conpassword){
            
            this.modal.block = false;
            this.modal.icon = 'error';
            this.modal.msg = 'Las contrseñas no coiciden';
            this.$refs.modal.open();
            return;
        }

        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        axios.post(tools.url('/api/user/password'), this.form).then((response)=>{
            
                this.modal.block = false;
                this.modal.icon = "success";
                this.modal.msg = response.data.msg;
                
        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = error.response.data;

        });
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Mi cuenta';
  }
}
</script>
