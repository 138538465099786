<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Fideicomisos</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <!-- <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> -->

    <section class="fideicomisos-tabs categories-tabs-section">
      <div class="box-tabs">
        <div class="_tab" v-for="(cat,indx) in categories" :key="'cat'+indx">
          <button class="btn-cat" v-bind:class="{ active : indx_cat == indx }" @click="indx_cat = indx">{{cat.name}}</button>
        </div>
      </div>
    </section>

    <section class="box-white-s1 results-section">
      <div class="row">
        <!-- <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option :value="null">Nombre ascendente</b-form-select-option>
                <b-form-select-option value="a">Nombre descendente</b-form-select-option>
              </b-form-select>
            </div>
          </b-form>
        </div> -->

        <div class="col-lg-6 col-xl-4 content-sample-1" v-for="(c, cInx) in categories[indx_cat].records" :key="'cInx-'+cInx">
          <div class="box">
            <div class="col col-info col-fideicomiso">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Beneficiario</h6>
                    <h5 class="name">{{ c.beneficiary }}</h5>
                  </div>
                </div>

                <div class="col-12 col-txt">
                  <h5> ID: <strong>{{ c.ide }}</strong></h5>
                </div>

                <div class="col-12 col-txt">
                  <h5>{{ c.currency }} | {{ c.type }}</h5>
                </div>

                <div class="col-12 col-txt">
                  <h5 class="f-w-600">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.money) }} {{ c.currency }}</h5>
                </div>

                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Nombre de la fiduciaria</h6>
                    <h5>{{ c.name_trustee }}</h5>
                  </div>
                </div>
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Tipo de fideicomiso</h6>
                    <h5>{{ c.type_trustee }}</h5>
                  </div>
                </div>
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Pago de honorarios</h6>
                    <h5>{{ c.payment_fees }}</h5>
                  </div>
                </div>
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Objetivos de fideicomiso</h6>
                    <h5>{{ c.trust_objectives }}</h5>
                  </div>
                </div>
                <div class="col-12 col-top">
                  <div class="col box-title">
                    <h6 class="_label">Comentarios</h6>
                    <h5>{{ c.comments }}</h5>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,
      indx_cat: 0,

      categories:[]
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
        axios.get(tools.url("/api/trusts"), this.form).then((response)=>{
            this.categories = response.data;
        }).catch((error)=>{
              this.handleErrors(error);
        });
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
    this.getRow();
  }
}
</script>
