<template lang="html">
  <div id="d-sidebar">
    <button class="btn-close t-150" @click="$parent.isMenuVisible = false">
      <i class="far fa-chevron-left"></i>
    </button>

    <div class="box-logo">
      <router-link :to="{ name: 'dashboardPage' }">
        <img src="public/images/logo-white.svg" alt="Asterion Capital Solutions">
      </router-link>
    </div>

    <div class="box-wr">
      <div class="box-link-section">
        <ul>
          <li>
            <router-link :to="{ name: 'dashboardPage' }">Dashboard Inicial</router-link><br><br>
          </li>
        </ul>

        <h4 class="_title">PATRIMONIO</h4>

        <ul>
          <li>
            <router-link :to="{ name: 'invFinanPage' }">Inversiones financieras</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'empresasPage' }">Empresa</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'fideicomisosPage' }">Fideicomisos</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'bienesRaicesPage' }">Bienes raíces</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'segurosPage' }">Seguros</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'actPersonalesPage' }">Activos personales</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'avalesPage' }">Avales</router-link>
          </li>
        </ul>
      </div>

      <div class="box-link-section">
        <h4 class="_title">PASIVOS</h4>

        <ul>
          <li>
            <router-link :to="{ name: 'sisFinancieroPage' }">Sistema financiero</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'pasPersonalesPage' }">Personales</router-link>
          </li>
        </ul>
      </div>

      <!-- <div class="box-link-section">
        <h4 class="_title">REPORTES</h4>

        <ul>
          <li>
            <router-link to="">Patrimonial</router-link>
          </li>
          <li>
            <router-link to="">Cuentas de inversión</router-link>
          </li>
          <li>
            <router-link to="">Empresas</router-link>
          </li>
          <li>
            <router-link to="">Bienes raíces</router-link>
          </li>
          <li>
            <router-link to="">Capital privado</router-link>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
