require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

window.Vue=Vue;

//Instancia principal
const app = new Vue({
    el: '#app',
    router:Router,
    data:{
        logged: false,
        user: {},
    },
    methods:{
        auth:function(){
              axios.get(tools.url("/api/user")).then((response)=>{
                this.user = response.data;
                this.logged = true;
                if(this.user.validate_verification_code == 0){
                    this.handleLogout();
                }
            }).catch(()=>{
              //no login
          });
        },
        handleLogout(){
            axios.post(tools.url("/api/logout")).then((response)=>{
                this.$root.user = null;
                this.$root.logged = false;
                this.$router.push('/');
            }).catch(()=>{});
        }
    },
    mounted:function(){
        this.auth();
    }
});
