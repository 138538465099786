<template lang="html">
  <div id="dashboard-home-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Periodo {{current_period}}</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <!-- <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> -->

    <section class="summary-section">
      <div class="row">
        <div class="col-md-4 col-summary">
          <div class="box-summary box-white-s1">
            <div class="box-title">Patrimonio neto</div>

            <div class="box-info">
              <div class="inside">
                <h4 class="box-price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totals.networth) }}</h4>

                <!-- <div class="box-data">
                  <div class="table">
                    <div class="t-row">
                      <div class="t-cell c-text">Variación mensual</div>
                      <div class="t-cell c-num">
                        3.5 y.
                        <i class="far fa-long-arrow-up icon"></i>
                        <*!-- <i class="far fa-long-arrow-down icon"></i> --*>
                      </div>
                    </div>

                    <div class="t-row">
                      <div class="t-cell c-text">Variación anterior</div>
                      <div class="t-cell c-num">$22,000,000.00</div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-summary">
          <div class="box-summary box-white-s1" style="cursor: pointer;" @click="$router.push('/dashboard/inversiones-financieras')">
            <div class="box-title">Portafolio financiero</div>

            <div class="box-info">
              <div class="inside">
                <div class="box-price">
                  <div class="n">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totals.financial_portfolio) }}</div>

                  <div class="box-currency-s1">
                    <span v-bind:class="{ active : currency == 'mxn' }" @click="currency = 'mxn'">MXN</span>
                    <span v-bind:class="{ active : currency == 'usd' }" @click="currency = 'usd'">USD</span>
                  </div>
                </div>

                <!-- <div class="box-data">
                  <div class="table">
                    <div class="t-row">
                      <div class="t-cell c-text">Variación mensual</div>
                      <div class="t-cell c-num">
                        3.5 y.
                        <i class="far fa-long-arrow-up icon"></i>
                        <*!-- <i class="far fa-long-arrow-down icon"></i> --*>
                      </div>
                    </div>

                    <div class="t-row">
                      <div class="t-cell c-text">Variación anterior</div>
                      <div class="t-cell c-num">$7,000,000.00</div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-summary" style="cursor: pointer;" @click="$router.push('/dashboard/sistema-financiero')">
          <div class="box-summary box-white-s1">
            <div class="box-title">Pasivos</div>

            <div class="box-info">
              <div class="inside">
                <div class="box-price">
                  <div class="n">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(totals.passives) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="maps-charts-section">
      <div class="row">
        <div class="col-lg-6 col-data">
          <div class="box-data box-white-s1">
            <div class="box-title">
              <div class="col b-left">
                <h5>Desglose patrimonio</h5>
              </div>

              <!-- <div class="col b-right">
                <a class="t-150 mini-btn">Descargar</a>
              </div> -->
            </div>

            <div class="box-content">
              <ccv-pie-chart :data='totals.datachart' :options='options'></ccv-pie-chart>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-data">
          <div class="box-data box-white-s1">
            <div class="box-title">
              <div class="col b-left">
                <h5>Distribución por región</h5>
              </div>

              <!-- <div class="col b-right">
                <a class="t-150 mini-btn">Descargar</a>
              </div> -->
            </div>

            <div class="box-content w-map" style="height: 100%;">
              <!-- NO QUITAR ESTA DIV -->
              <div id="dirtyDiv"></div>

              <!-- <svg-map :map="World"/> -->

              <GmapMap
                style="width: 100%;height: 100%;"
                :center="mapCenter"
                :zoom="3"
              :options="mapOptions"
                v-if="showMap">
                <GmapMarker
                  :key="'marker-'+mInx"
                  v-for="(m, mInx) in totals.maps"
                  :position="{lat: m.lat, lng: m.lng}"
                  :title="m.name"
                  :clickable="true"
                  :draggable="false"
                @click="$router.push(gmapInfo.url)"
                  @mouseover="markAction(m)"
                  @mouseout="windowOpen = false"
                  v-if="m.lat != 0 && m.lng != 0"
                />

                <gmap-info-window
                  @closeclick="windowOpen = false"
                  :opened="windowOpen"
                  :position=" {lat: gmapInfo.lat, lng: gmapInfo.lng}"
                  :options="{
                    pixelOffset: {
                      width: 0, height: -35
                    }
                  }"
                >
                  <router-link class="gmap-box-info" :to="(gmapInfo.url)">
                    <div class="box-info">
                      <div class="photo">
                        <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+gmapInfo.imageUrl+')' }"></div>
                      </div>
                    </div>

                    <div class="box-bottom">
                      <span >{{ gmapInfo.name }}</span>
                    </div>
                  </router-link>
                </gmap-info-window>
              </GmapMap>

            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
// == Imports para el mapa ( CON VUE: https://github.com/VictorCazanave/vue-svg-map | SIN VUE: https://github.com/StephanWagner/svgMap) ==
// Para saber el "code" de cada pais, se usa el "ISO 3166-1 alpha-2" (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
import { SvgMap } from "vue-svg-map";
import World from "@svg-maps/world";
// == ==
export default {
	components: {
		SvgMap
	},

  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Values necesarios para los gráficos ==
      datachart: [
        {
          "group": "Bienes raices",
          "value": 20
        },
        {
          "group": "Finanzas",
          "value": 40
        },
        {
          "group": "Empresas",
          "value": 25
        },
        {
          "group": "Cheques",
          "value": 45
        },
      ],

      options: {
        // "title": "Pie",
        "resizable": true,
        "height": "400px",
        "legend": {
          "alignment": "center"
        },
        "pie": {
          "alignment": "center"
        },
        "toolbar":{
          "enabled":false
        },
      },
      // == ==

      // Value necesario para el mapa (cuidado, NO MOVER)
      World,
      countriesArr: ['mx','ca', 'us' ,'br', 'cn', 'jp'], // Codigos de paises segun el ISO 3166-1 alpha-2
      // == ==
      mapOptions: {
        styles: [
          {
              "featureType": "administrative",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": "-100"
                  }
              ]
          },
          {
              "featureType": "administrative.province",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": 65
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": "50"
                  },
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": "-100"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "all",
              "stylers": [
                  {
                      "lightness": "30"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "all",
              "stylers": [
                  {
                      "lightness": "40"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "hue": "#ffff00"
                  },
                  {
                      "lightness": -25
                  },
                  {
                      "saturation": -97
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "labels",
              "stylers": [
                  {
                      "lightness": -25
                  },
                  {
                      "saturation": -100
                  }
              ]
          }
        ],
        mapTypeControl:false,
        streetViewControl:false,
      },
      current_period:null,
      totals:{
        networth:0,
        datachart:[]
      },

      showMap: false, // tru, false para mostrar el mapa (el plugin)
      windowOpen: false, // true, false para mostrar la ventana de los markers
      mapCenter: { lat:19.4402725, lng:-99.103795 }, // Centro del mapa
      gmapInfo: { // Obj para la ventana de los markers
        lat: 0, 
        lng: 0,
        content: ``
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },

    fillCountries() { // Debe ir en "mounted()" o despues
      let ids = this.countriesArr.map(function(country) { // Agregar el # para reconocer el id a poner la clase css
        return '#'+country
      });
      ids = ids.toString();

      const elem = document.getElementById("dirtyDiv");
      elem.insertAdjacentHTML("beforeend", `
        <style>
          ${ids}{
            fill:#001f44 !important; stroke: #001f44 !important;
          }
        </style>`
      );
    },
    getRow(){
      axios.get(tools.url("/api/totals"), this.form).then((response)=>{
          this.totals = response.data;
      }).catch((error)=>{
          this.handleErrors(error);
      });
    },
    markAction(val){
      // console.log(this);
      this.gmapInfo = val;
      this.windowOpen = true;
    },
  },

  mounted() {
    this.getRow();
    this.$parent.sectionTitle = 'Dashboard';
    this.fillCountries();

      var fecha = new Date();
		  var anio = fecha.getFullYear();
		  var _dia = fecha.getDate();
		  var _mes = fecha.getMonth();//viene con valores de 0 al 11
		  _mes = _mes + 1;//ahora lo tienes de 1 al 12
      if (_mes == 1) {
          this.current_period = 'Enero de '+anio;
      }
		  else if (_mes == 2) {
          this.current_period = 'Febrero de '+anio;
      }
      else if (_mes == 3) {
          this.current_period = 'Marzo de '+anio;
      }
      else if (_mes == 4) {
          this.current_period = 'Abril de '+anio;
      }
      else if (_mes == 5) {
          this.current_period = 'Mayo de '+anio;
      }
      else if (_mes == 6) {
          this.current_period = 'Junio de '+anio;
      }
      else if (_mes == 7) {
          this.current_period = 'Julio de '+anio;
      }
      else if (_mes == 8) {
          this.current_period = 'Agosto de '+anio;
      }
      else if (_mes == 9) {
          this.current_period = 'Septiembre de '+anio;
      }
      else if (_mes == 10) {
          this.current_period = 'Octubre de '+anio;
      }
      else if (_mes == 11) {
          this.current_period = 'Noviembre de '+anio;
      }
      else if (_mes == 12) {
          this.current_period = 'Diciembre de '+anio;
      }
      setTimeout(()=> {
        this.showMap = true;
      }, 1000);

  }
}
</script>

<!-- <style src="vue-svg-map/dist/index.css"></style> -->
