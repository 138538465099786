<template lang="html">
  <div id="content-t1-page">

    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Bienes raíces</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 outline-black mr-2">Exportar</button>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <section class="px-0 results-section">
      <div class="row">
        <div class="col-xl-8 mb-0 content-sample-1 xl">
          <div class="box box-white-s1">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+ row.imageUrl +')' }" @click="inddx = 0" style="cursor: pointer;"></div>
            <CoolLightBox
                  :items="row.gallery"
                  :index="inddx"
                  :effect="'fade'"
                  :useZoomBar="true"
                  loop
                  @close="inddx = null">
              </CoolLightBox>
            <div class="col col-info">
              <div class="row">
                <div class="col-12 col-top">
                  <div class="col-12 box-title">
                    <h5 class="mb-2 name">{{row.name}}</h5>

                    <p class="txt-black descr">
                      {{ row.address }}, {{ row.state }}, {{ row.town }}, {{ row.country }}
                    </p>
                  </div>

                  <div class="box-icons-s1">
                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-metters.png" /></div>
                      <span class="text">{{ row.size }}m2</span>
                    </div>

                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-rooms.png" /></div>
                      <span class="text">{{ row.bedroom }} cuartos</span>
                    </div>

                    <div class="box-icon">
                      <div class="icon"><img src="public/images/pages/account/ic-bathroom.png" /></div>
                      <span class="text">{{ row.baths }} baños</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-txt">
                  <p class="h6">
                    {{ row.description }}
                  </p>
                </div>

                <div class="col-4 col-txt">
                  <h5>Construido:</h5>
                  <h4 class="f-w-600 txt-black">{{ row.year_built }}</h4>
                </div>

                <div class="col-4 col-txt">
                  <h5>Cochera:</h5>
                  <h4 class="f-w-600 txt-black">{{ row.parking_lot }} auto</h4>
                </div>

                <div class="col-4 col-txt">
                  <h5>ID de propiedad:</h5>
                  <h4 class="f-w-600 txt-black">{{ row.ide }}</h4>
                </div>

                  <div class="col-12 box-title">
                    <a v-for="(doc,indxd) in row.documents" :key="'doc'+indxd" :href="doc.documentUrl" target="_blank" class="btn btn-info" style="background-color: #000; border-color: #000; margin-right: 10px;"><i class="fas fa-download"></i></a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 mt-xl-0 col-xl-4 col-extra-info">
          <div class="d-block h-100 p-3 box-white-s1">
            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Valor comercial</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.commercial_value) }} MXN</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Valor catastral</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.cadastral_value) }} MXN</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Tipo de operación</h5>
              <h4 class="f-w-700 txt-l-h-1-2">{{row.type_operation}}</h4>
            </div>

            <div class="d-block mb-3">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Estado</h5>
              <h4 class="f-w-700 txt-l-h-1-2">{{row.status}}</h4>
            </div>

            <!-- <div class="d-block">
              <h5 class="f-w-400 txt-l-h-1-1 txt-gray">Dividendo</h5>
              <h4 class="f-w-700 txt-l-h-1-2">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.dividend) }} MXN</h4>
            </div> -->
          </div>
        </div>

        <div class="col-12 mt-4 col-charts">
          <div class="box box-white-s1">
            <div class="text-center box-title">
              <h5>Dividendos actuales vs Año anterior</h5>
            </div>

            <div class="box-content">
              <ccv-line-chart :data='row.datachart' :options='options'></ccv-line-chart>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'; // https://vuejsexamples.com/vue-js-lightbox-package-without-any-dependencies/
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      // == Graficos ==
      data: [],

      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Años",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "mapsTo": "value",
            "title": "Valor",
            "scaleType": "linear"
          }
          
        },
        "toolbar":{
          "enabled":false
        },
        "height": "400px",
      },
      row:{
        datachart:[],
        gallery:[]
      },
      inddx:null
      // == ==
    }
  },
  methods: {

    getRow(){
        axios.get(tools.url("/api/realstate/"+this.$route.params.id)).then((response)=>{
            this.row = response.data;
        }).catch((error)=>{
              this.handleErrors(error);
        });
    }
  },
  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
    this.getRow();
  }
}
</script>
