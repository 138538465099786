<template lang="html">
  <div id="content-t1-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Pasivos personales</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <!-- <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> -->

    <section class="box-white-s1 results-t2-section">
      <div class="row">
        <div class="col-12 col-filters">
          <b-form @submit="onSubmit">
            <!-- <div class="box-filter-s1">
              <label>Filtrar por</label>

              <b-form-select v-model="filters">
                <b-form-select-option :value="null">Actualización</b-form-select-option>
                <b-form-select-option value="a">Mayor a menor</b-form-select-option>
                <b-form-select-option value="b">Menor a mayor</b-form-select-option>
              </b-form-select>
            </div> -->
          </b-form>
        </div>

        <div class="col-sm-6 col-xl-4 xxl-4 content-sample-2" v-for="(o, oInx) in rows" :key="'oInx-'+oInx">
          <div class="box">
            <div class="col-12 placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+o.imageUrl+')' }" @click="inddx = oInx" style="cursor: pointer;"></div>
            <CoolLightBox
                  :items="o.gallery"
                  :index="inddx"
                  :effect="'fade'"
                  :useZoomBar="true"
                  loop
                  @close="inddx = null">
              </CoolLightBox>

            <div class="col-12 col-info">
              <h5 class="name">{{ o.name }}</h5>
              <p>{{ o.category }}</p>

              

              <!-- <p><a href="">Descargar factura</a></p> -->
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Valor</h6>
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(o.value) }} {{ o.currency }}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Valor de compra</h6>
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(o.purchase_value) }} {{ o.currency }}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Valor estimado actual</h6>
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(o.current_value) }} {{ o.currency }}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Moneda</h6>
                  <h6 class="price">{{ o.currency }} </h6>
                </div>
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Plusvalía</h6>
                  <h6 class="price">{{ o.goodwill}} </h6>
                </div>
            </div>
            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Año de compra</h6>
                  <h6 class="price">{{ o.year_purchase}} </h6>
                </div>
            </div>

            <div class="col-12">
                <div class="col box-title">
                  <h6 class="_label" style="margin-bottom: 1px;color: #5d5b5b;font-size: 0.8rem;font-weight: 400;">Descripción</h6>
                  <h6 class="price">{{ o.description}} </h6>
                </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'; // https://vuejsexamples.com/vue-js-lightbox-package-without-any-dependencies/
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      filters: null,
      category: 0,

      rows: [],
      inddx:null,
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
        axios.get(tools.url("/api/personalliabilities"), this.form).then((response)=>{
            this.rows = response.data;
        }).catch((error)=>{
            this.handleErrors(error);
        });
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Pasivos';
    this.getRow();
  }
}
</script>
