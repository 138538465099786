<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Cambiar datos</h2>
        </div>
      </div>
    </section>

    <section class="account-section">
      <div class="box box-white-s1">
        <b-form @submit="onSubmit">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 black col-12" label="Correo electrónico">
                <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Nombre">
                <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Apellido">
                <b-form-input type="text" v-model="form.lastname" required placeholder=""></b-form-input>
              </b-form-group>
            </div>
             

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Dirección">
                <b-form-input type="text" v-model="form.address" placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Colonia">
                <b-form-input type="text" v-model="form.neighborhood" placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-6">
              <b-form-group class="cus-f-group-1 black col-12" label="Teléfono">
                <b-form-input type="text" v-model="form.phone" minlength="10" maxlength="10" placeholder=""></b-form-input>
              </b-form-group>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 bg-black">Cambiar datos</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        
      </sweet-modal>

    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario  ==
      form: {
        email: null,
        name: null,
        lastname:null,
        address:null,
        neighborhood:null,
        phone:null,
      },
      // == ==
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Guardando informacion...';
        this.$refs.modal.open();

        axios.post(tools.url("/api/user/profile"), this.form).then((response)=>{
          this.modal.block = false;
            this.modal.icon = 'success';
            this.modal.msg = response.data.msg;
            this.$root.auth();
        }).catch((error)=>{
          this.modal.block = false;
            this.modal.icon= "error";
            this.modal.msg = error.response.data;
            
        });
    }
  },

  mounted() {
    this.form.email = this.$root.user.email;
    this.form.name = this.$root.user.name;
    this.form.lastname = this.$root.user.lastname;
    this.form.address = this.$root.user.address;
    this.form.neighborhood = this.$root.user.neighborhood;
    this.form.phone = this.$root.user.phone;
    
    this.$parent.sectionTitle = 'Mi cuenta';
    
  }
}
</script>
