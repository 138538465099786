/*
 *
 * Estos componentes estan presentes solo en el website
 *
 */

const page="./page/";
// import slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// import VueSweetalert2 from 'vue-sweetalert2';

// == Graficos (https://charts.carbondesignsystem.com/vue/?path=/story/intro--welcome) ==
//import "@carbon/styles/css/styles.css";
import "@carbon/charts/styles.css";
import chartsVue from "@carbon/charts-vue";
// == ==

function plugin (Vue) {
	//Componentes llamados
  Vue.component('my-sidebar', require(page+'shared/sidebar.vue').default);
  Vue.component('my-header', require(page+'shared/header.vue').default);
	Vue.component('my-footer', require(page+'shared/footer.vue').default);
	// Vue.component('slick',slick);
  Vue.use(BootstrapVue);
  // Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
  // Vue.use(VueSweetalert2);
  Vue.use(chartsVue);
}

// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
