<template lang="html">
  <div class="container-fluid" id="user-account-page">
    <div class="row">
      <div class="col-lg col-sidebar" v-bind:class="{ active : isMenuVisible }">
        <div class="btn-bg" @click="isMenuVisible = false"></div>
        <my-sidebar></my-sidebar>
      </div>

      <div class="col-lg col-content">
        <div class="container main-container">
          <div id="dynamic-content">
            <my-header :title="sectionTitle"></my-header>
            <router-view></router-view>
          </div>

          <my-footer></my-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuVisible: false,
      sectionTitle: 'Dashboard',
    }
  }
}
</script>
