<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Inversiones financieras</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <div class="mr-2 box-total-s1">
            <h6>Total de todas las cuentas</h6>
            <h5>$7,000,000.00 MXN</h5>
          </div>
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <!-- <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input> -->
        <div>
          <p style="text-align: center;">Institucion</p>
          <b-form-select class="col" v-model="form.institution">
             <b-form-select-option :value="null" disabled>Selecciona una institución</b-form-select-option>
              <b-form-select-option :value="row.institution" v-for="(row,indx) in institutions" :key="'invs'+indx" >{{row.institution}}</b-form-select-option>
          </b-form-select>
        </div>
        <div>
          <p style="text-align: center;">Mes</p>
          <b-form-select class="col" v-model="form.option">
            <b-form-select-option :value="null" disabled>Selecciona un mes</b-form-select-option>
            <b-form-select-option value="Enero">Enero</b-form-select-option>
            <b-form-select-option value="Febrero">Febrero</b-form-select-option>
            <b-form-select-option value="Marzo">Marzo</b-form-select-option>
            <b-form-select-option value="Abril">Abril</b-form-select-option>
            <b-form-select-option value="Mayo">Mayo</b-form-select-option>
            <b-form-select-option value="Junio">Junio</b-form-select-option>
            <b-form-select-option value="Julio">Julio</b-form-select-option>
            <b-form-select-option value="Agosto">Agosto</b-form-select-option>
            <b-form-select-option value="Septiembre">Septiembre</b-form-select-option>
            <b-form-select-option value="Octubre">Octubre</b-form-select-option>
            <b-form-select-option value="Noviembre">Noviembre</b-form-select-option>
            <b-form-select-option value="Diciembre">Diciembre</b-form-select-option>
          </b-form-select>
        </div>

        <div>

        </div>
      </b-form>
    </section>

    <section class="results-section">
      <b-card class="card-tabs-s1" no-body>
        <p style="text-align: center;" v-if="row_selected == null">Selecciona un mes para consultar</p>
        <b-tabs fill>

          <b-tab :title="row_selected ? row_selected.name : 'Sin información existente'">
            <div style="text-align: right;" v-if="row_selected != null">
                <a type="button" style="background-color: black;border-color: black;" class="btn btn-primary btn-sm pull-right" :href="'./api/investmentsExport?institution='+form.institution+'&month='+form.option" download="reporte.xlsx" ><i class="fa fa-file-excel"></i> Exportar</a>
            </div>

            <div class="_tab-content">
              <div class="row" v-if="row_selected != null">
                <div class="col-lg-6 col-table">
                  <div class="box-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" class="c-title">Resumen del portafolio</th>
                          <th scope="col" class="c-currency">
                            <div class="box-currency-s1">
                              <span v-bind:class="{ active : currency == 'mxn' }" @click="currency = 'mxn'">MXN</span>
                              <span v-bind:class="{ active : currency == 'usd' }" @click="currency = 'usd'">USD</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Resumen del portafolio</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( row_selected ? row_selected.portfolio_summary : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format( row_selected ? row_selected.portfolio_summary_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Saldo inicial del periodo</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.beginning_balance : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.beginning_balance_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Entradas efectivo/valores</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.entries : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.entries_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Saldo efectivo/valores</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.cash : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.cash_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Plusvalía / Minusvalía</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.gain_loss : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.gain_loss_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Saldo final</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.final_balance : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.final_balance_usd : 0) }} USD</td>
                        </tr>
                        <tr>
                          <th>Rendmiento</th>
                          <td class="n" v-if="currency == 'mxn'">{{ row_selected ? row_selected.performance_percent : 0 }} %</td>
                          <td class="n" v-if="currency == 'usd'">{{ row_selected ? row_selected.performance_percent : 0 }} %</td>
                        </tr>

                        <tr>
                          <th>Equity</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.equity : 0) }} </td>
                          
                        </tr>
                        <tr>
                          <th>Cash / money accounts</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.cash_money_accounts : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Retiros del mes</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.withdrawals_month : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Depósitos del mes</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.deposits_month : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Instrumentos estructurados:</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.structured_instruments : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Instrumentos alternativo:</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.alternative_instruments : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Fixed income:</th>
                          <td class="n" >$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.fixed_income : 0) }} </td>
                        </tr>
                        <tr>
                          <th>Rendimiento del portafolio del mes:</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.portaflio_performance_month : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.portaflio_performance_month_usd : 0) }} USD</td>
                     
                        </tr>
                        <tr>
                          <th>Rendimiento del portafolio anual:</th>
                          <td class="n" v-if="currency == 'mxn'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.portaflio_performance_anual : 0) }} MXN</td>
                          <td class="n" v-if="currency == 'usd'">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row_selected ? row_selected.portaflio_performance_anual_usd : 0) }} USD</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-6 col-charts">
                  <div class="box-title">
                    <h5>Rendimiento conforme al periodo anterior</h5>
                  </div>

                  <div class="box-content">
                    <ccv-line-chart :data='chartdata' :options='options'></ccv-line-chart>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- <b-tab title="Inverlat">
            <b-card-text>Pestaña 2</b-card-text>
          </b-tab>
          <b-tab title="UBS">
            <b-card-text>Pestaña 3</b-card-text>
          </b-tab>
          <b-tab title="CITI">
            <b-card-text>Pestaña 4</b-card-text>
          </b-tab>
          <b-tab title="CHASE">
            <b-card-text>Pestaña 5</b-card-text>
          </b-tab>
          <b-tab title="SCOTIA">
            <b-card-text>Pestaña 6</b-card-text>
          </b-tab> -->
        </b-tabs>
      </b-card>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        institution: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Graficos ==
      data: [],
      chartdata:[],
      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Meses",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "mapsTo": "value",
            "title": "Cantidad",
            "scaleType": "linear"
          }
        },
        "toolbar":{
          "enabled":false
        },
        "tooltip":{
          "enabled":false
        },
        "height": "400px",
      },
      // == ==
      indx_active:null,
      row_selected:null,
      institutions:[],
    }
  },
  watch:{
      form: {
        handler(val){
            var indx = 'a';
            var data_chart = [];
            for (let x = 0; x < this.data.length; x++) {
                if(this.data[x]['institution'] == this.form.institution){
                  if(this.data[x]['month'] == this.form.option ){
                      indx = x;
                  }
                  var aux = {
                      group:'Rendimiento',
                      key:this.data[x]['month'],
                      value:this.data[x]['performance_percent'],
                  };
                  data_chart.push(aux);
                }
 
            } 

            if(indx != 'a'){
              this.row_selected = this.data[indx];
              this.chartdata = data_chart;
            }
            else{
              this.row_selected = null;
              this.chartdata = null;
            }
            
        },
        deep: true
      },
      
  },    
  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
        axios.get(tools.url("/api/investments"), this.form).then((response)=>{
            this.data = response.data.data;
            this.institutions = response.data.institutions;
            this.indx_active = 0;
        }).catch((error)=>{
              this.handleErrors(error);
        });
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
    this.getRow();
  }
}
</script>
