<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Cambiar imagen</h2>
        </div>
      </div>
    </section>

    <section class="account-section">
      <div class="box box-white-s1">
        <b-form @submit.prevent="onSubmit($event.target)">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group class="cus-f-group-1 group-fileinput black col-12" label="Correo electrónico">
                <b-form-file
                name="image"
                  v-model="form.imageFile"
                  placeholder="Selecciona una imagen o sueltala aquí"
                  drop-placeholder="Suelta la imagen aquí"
                ></b-form-file>
              </b-form-group>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 bg-black">Cambiar imagen</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        
      </sweet-modal>

    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario  ==
      form: {
        imageFile: null,
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },  
      // == ==
    }
  },

  methods: {
    onSubmit(form) {
      
      this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Guardando informacion...';
        this.$refs.modal.open();
        var data=tools.params(form,this.form);
        axios.post(tools.url("/api/user/profileimage"),data).then((response)=>{
          this.modal.block = false;
            this.modal.icon = 'success';
            this.modal.msg = response.data.msg;
            this.$root.auth();
        }).catch((error)=>{
          this.modal.block = false;
            this.modal.icon= "error";
            this.modal.msg = error.response.data;
            
        });
    },
  },

  mounted() {
    this.$parent.sectionTitle = 'Mi cuenta';
  }
}
</script>
