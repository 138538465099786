<template lang="html">
  <div id="content-t2-page">
    <section class="page-top-section">
      <div class="row">
        <div class="col-lg-5 col-title">
          <h2>Avales</h2>
        </div>

        <!-- <div class="col-lg-7 col-actions">
          <button type="button" class="btn btn-s2 bg-black">Descargar reporte</button>
        </div> -->
      </div>
    </section>

    <!-- <section class="top-search-section">
      <b-form class="box-white-s1" @submit="onSubmit">
        <b-button type="submit" class="col btn-s"><i class="fal fa-search"></i></b-button>

        <b-form-input
          class="col"
          v-model="form.keywords"
          type="text"
          placeholder="Buscar..."
        ></b-form-input>

        <b-form-select class="col" v-model="form.option">
          <b-form-select-option :value="null">Todas las secciones</b-form-select-option>
          <b-form-select-option value="a">Opción A</b-form-select-option>
          <b-form-select-option value="b">Opción B</b-form-select-option>
        </b-form-select>
      </b-form>
    </section> -->

    <section class="results-section">
      <b-card class="card-tabs-s1" no-body>
        <b-tabs fill>
          <b-tab :title="row.name" v-for="(row,indx) in data" :key="'cat'+indx">
            <div class="_tab-content">
              <div class="row">
                <div class="col-lg-12 col-info">
                  <h4 class="mb-2 tab-title">{{row.name_person}}</h4>

                  <!-- Table -->
                  <div class="fake-table">
                    <div class="t-row">
                      <div class="th">Tipo de contrato</div>
                      <div class="td">{{row.type_contract}}</div>
                    </div>
                  
                    <div class="t-row">
                      <div class="th">Vigencia</div>
                      <div class="td">{{row.validity}}</div>
                    </div>
                    <div class="t-row">
                      <div class="th">Saldo</div>
                      <div class="td">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(row.balance) }}</div>
                    </div>

                    <div class="t-row">
                        <a v-for="(doc,indxd) in row.documents" :key="'doc'+indxd" :href="doc.documentUrl" target="_blank" class="btn btn-info" style="background-color: #000; border-color: #000; margin-right: 10px;"><i class="fas fa-download"></i></a>
                      
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
          </b-tab>

          
        </b-tabs>
      </b-card>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // == Formulario de búsqueda ==
      form: {
        keywords: null,
        option: null,
      },
      // == ==

      // Divisa
      currency: 'mxn',

      // == Graficos ==
      data: [],

      options: {
        // "title": "El título",
        "axes": {
          "bottom": {
            "title": "Meses",
            "mapsTo": "key",
            "scaleType": "labels"
          },
          "left": {
            "mapsTo": "value",
            "title": "Cantidad",
            "scaleType": "linear"
          }
        },
        "height": "400px",
      },
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Buscando...');
    },
    getRow(){
      axios.get(tools.url("/api/endorsements"), this.form).then((response)=>{
          this.data = response.data;
      }).catch((error)=>{
          this.handleErrors(error);
      });
    }
  },

  mounted() {
    this.$parent.sectionTitle = 'Patrimonio';
    this.getRow();
  }
}
</script>
